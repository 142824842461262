<template>
  <v-card>
    <v-card-text>
      <v-form ref="certificateForm">
        <v-row>
          <v-col cols="12" sm="6" md="3">
            <date-picker
              v-model="date[0]"
              label="START DATE"
              :rules="[v => !!v || 'Required']"
              @clear="date = [null, null]"
            />
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <date-picker
              v-model="date[1]"
              label="END DATE"
              :minDate="date[0]"
              :disable="!date[0]"
            />
          </v-col>
          <v-col cols="12" sm="4" md="3" class="pt-0 pb-0">
            <v-radio-group
              v-model="dateType"
              row
              :rules="rule"
              dense
              mandatory
              class="mt-0"
            >
              <v-radio
                label="Filter on Entry Date"
                value="entry"
                key="entry"
              ></v-radio>
              <v-radio
                label="Filter on Tag Date"
                value="tag"
                key="tag"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <!-- <v-col cols="12" sm="5" md="3" class="pt-0 pb-0">
            <v-radio-group
              v-model="role"
              row
              :rules="rule"
              dense
              mandatory
              class="mt-0"
            >
              <v-radio
                label="Certificates (Members)"
                value="angler"
                key="angler"
              ></v-radio>
              <v-radio
                label="Certificates - Captains (Members)"
                value="captain"
                key="captain"
              ></v-radio>
            </v-radio-group>
          </v-col> -->
          <v-col cols="12" sm="3" md="2">
            <v-btn block class="primary" @click="getData" :disabled="loading">
              Get
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <list-view
      ref="listView"
      title="Certificates Report"
      :headers="headers"
      :records="records"
      :async-loading="loading"
      :total-docs="totalDocs"
      itemKey="id"
      @onPaginate="v => (options = v)"
      exportable
      @export="v => exportFile(v)"
      scrollable
    />
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import ListView from '../../components/appComponents/ListView.vue';
import { getFile } from '../../helpers/handler';
import DatePicker from '../../components/appComponents/DatePicker.vue';
export default {
  components: { ListView, DatePicker },
  name: 'certificates',
  mounted() {
    this.clearForm();
  },
  data: () => ({
    start_year: null,
    end_year: null,
    search: null,
    records: [],
    certifications: 0,
    rule: [v => !!v || 'Required'],
    // footer: {
    //   pageCount: 0
    // },
    loading: false,
    totalDocs: 0,
    headers: Headers,
    date: [null, null],
    dateType: 'entry',
    role: 'angler',
    options: null,
    overlay: false
  }),
  watch: {
    options: {
      handler: 'getData'
    }
  },
  methods: {
    ...mapActions('query', ['getCertifications']),
    async getData() {
      const valid =
        this.$refs.certificateForm && this.$refs.certificateForm.validate();
      if (valid) {
        this.loading = true;
        const response = await this.getCertifications({
          page: this.options?.page,
          limit: this.options?.itemsPerPage,
          date: this.date,
          role: this.role,
          date_type: this.dateType
        });

        this.records = response?.data?.docs || [];
        // this.footer.pageCount = response?.data?.totalPages || 0;
        // this.footer.page = response?.data?.page || 0;
        this.totalDocs = response?.data?.totalDocs || 0;

        this.loading = false;
      }
    },
    clearForm() {
      this.$refs.certificateForm && this.$refs.certificateForm.reset();
    },
    async exportFile(format) {
      this.$refs.listView && (this.$refs.listView.loadingExport = true);
      const response = await this.getCertifications({
        page: this.options?.page,
        limit: this.options?.itemsPerPage,
        date_type: this.dateType,
        date: this.date,
        role: this.role,
        export: true,
        format
      });

      getFile(response?.data, format, 'Certificates Report');
      this.$refs.listView && (this.$refs.listView.loadingExport = false);
    }
  }
};

const Headers = [
  {
    text: 'F_NAME_ANG',
    align: 'start',
    sortable: false,
    value: 'F_NAME_ANG'
  },
  {
    text: 'L_NAME_ANG',
    align: 'start',
    sortable: false,
    value: 'L_NAME_ANG'
  },
  {
    text: 'Number of Releases',
    align: 'start',
    sortable: false,
    value: 'FISH',
    width: '180px'
  },
  {
    text: 'BOAT',
    align: 'start',
    sortable: false,
    value: 'BOAT'
  },
  {
    text: 'F_NAME_CAP',
    align: 'start',
    sortable: false,
    value: 'F_NAME_CAP'
  },
  {
    text: 'L_NAME_CAP',
    align: 'start',
    sortable: false,
    value: 'L_NAME_CAP'
  },
  {
    text: 'FISH_LOCAL',
    align: 'start',
    sortable: false,
    value: 'FISH_LOCATION'
  },
  {
    text: 'FISH_DATE1',
    align: 'start',
    sortable: false,
    value: 'FISH_DATE1'
  },
  {
    text: 'FISH_DATE2',
    align: 'start',
    sortable: false,
    value: 'FISH_DATE2'
  },
  {
    text: 'A_ADDRESS',
    align: 'start',
    sortable: false,
    value: 'A_ADDRESS'
  },
  {
    text: 'A_CITY',
    align: 'start',
    sortable: false,
    value: 'A_CITY'
  },
  {
    text: 'A_STATE',
    align: 'start',
    sortable: false,
    value: 'A_STATE'
  },
  {
    text: 'A_ZIP',
    align: 'start',
    sortable: false,
    value: 'A_ZIP'
  },
  {
    text: 'A_COUNTRY',
    align: 'start',
    sortable: false,
    value: 'A_COUNTRY'
  }
];
</script>
